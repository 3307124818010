import * as React from "react"
import * as FaIcons from "react-icons/fa"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <section id="not-found">
    <Seo title="404: Not found" />
    <div className="container">
      <h1>Sorry, we couldn't find the page you were looking for</h1>
      <FaIcons.FaFrownOpen id="not-found-icon" />
    </div>
  </section>
)

export default NotFoundPage
